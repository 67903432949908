import { globalEnvironment } from "./globalEnvironment";
import { labelsCatasia } from "../app/resource/labels";

const baseURL = "";

export const environment = {
  environment: "villavicencio",
  production: true,
  name: "villavicencio",
  host: globalEnvironment,
  API_URL: `${baseURL}/api/private/`,
  API_LOGIN_URL: `${baseURL}/api/security/oauth/`,
  labels: labelsCatasia["version2.0"],

  //GUI
  theme: "villavicencio",
  logoLogin: "assets/layout/images/villavicencio/nombre.svg",
  loginText: null,
  logoTopBar: "assets/layout/images/villavicencio/topbar.svg",
  sizeLogoTopBar: "80px",
  textTopBar: null,
  logofooterEsri: null,
  logofooterCatasIA: null,
  logofooterEsriHeight: null,
  logofooterCatasIAHeight: null,
  logoLoginHeight: "180px",
  fondoLogin: [
    "assets/layout/images/villavicencio/fondos/1.webp",
    "assets/layout/images/villavicencio/fondos/2.webp",
    "assets/layout/images/villavicencio/fondos/3.webp",
    "assets/layout/images/villavicencio/fondos/4.webp",
    "assets/layout/images/villavicencio/fondos/5.webp",
  ],
};